<template>
  <div>
    <Newheader></Newheader>
    <div class="flex-col page">
      <div class="flex-col section">
        <div class="flex-col items-center image-wrapper">
          <img data-aos="fade-up" class="image"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16655368351992433351.png" />
        </div>
        <div class="flex-col space-y-31 group">
          <div data-aos="fade-up" class="flex-row space-x-11">
            <span class="font_1">纯电专属</span>
            <span class="font_1">全面定义</span>
          </div>
          <div style="min-height: 5rem;" class="flex-row">
            <!-- <span class="font_1 text">全球电动车市场智能化解决方案</span>
        <div class="section_2"></div> -->
            <vue-typed-js v-if="show" class="desc" :strings="['全球电动车市场智能化解决方案']" :contentType="'html'" :loop="false" :startDelay="300" :typeSpeed="100"
              :backSpeed="50">
              <span class="typing font_1 text" ></span>
            </vue-typed-js>

          </div>
        </div>
        <img  data-aos="fade-up" class="image_2"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16655368351986520122.png" />
        <div class="flex-col space-y-30 group_2">
          <div data-aos="fade-up">
            <span class="font_2">ionex车能网，是光阳集团自主研发的</span>
            <span class="font_3">智能安全出行生态系统</span>
            <span class="font_2">，其核心特征是</span>
            <span class="font_3">
              硬件层、系统层以及生态层
              <br />
            </span>
            <span class="font_2">均进行深度开发，是一个从硬件到芯片、云端再到应用的全生态架构</span>
          </div>
          <div data-aos="fade-up" class="group_3">
            <span class="font_2">同时围绕电动摩托车为核心的驾驶体验，ionex拥有强大的</span>
            <span class="font_3">自我迭代能力</span>
            <span class="font_2">
              ，
              <br />
            </span>
            <span class="font_2">为全面开创两轮智能出行的新体验打下坚实的基础。</span>
          </div>
        </div>
        <div class="flex-col items-center image-wrapper_2">
          <img data-aos="fade-up" class="image_3"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16655368351981350024.png" />
        </div>
        <div data-aos="fade-up" class="flex-col group_4">
          <span class="font_4">摩托车领域 领创能源智慧共享体系</span>
          <div class="group_5">
            <span class="font_5">
              英捷鑫智能共享云端，拥有与两轮出行相匹配的，广泛的能源补给布设方式。
              <br />
            </span>
            <span class="font_5">
              在家可以灵活车电分离智能充电、也可以随处在最近的商家进行快充、换电，依托云技术，
              <br />
            </span>
            <span class="font_5">搭建共享可充+可租、可换、可升级的能源补给体系，为车主提供全场景化的加电服务。</span>
          </div>
        </div>
        <div data-aos="fade-up" class="flex-col space-y-44 group_6">
          <img class="image_4"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16655368351987331820.png" />
          <div class="flex-col">
            <div class="flex-col">
              <div class="section_3"></div>
              <div class="section_4"></div>
            </div>
            <div class="flex-row justify-between group_7">
              <span class="font_6 text_2">在家</span>
              <span class="font_6 text_3">在外</span>
            </div>
          </div>
        </div>
        <div class="flex-col items-center image-wrapper_3">
          <img class="image_5"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16655368351983666121.png" />
        </div>
        <div data-aos="fade-up" class="flex-row space-x-4 group_8">
          <span class="font_4 text_4">随心所换</span>
          <span class="font_4 text_5">多重保障</span>
        </div>
        <div data-aos="fade-up" class="flex-col space-y-53 group_9">
          <div class="flex-row space-x-58">
            <div class="flex-col items-center section_5">
              <div style="width: 100%;">
              <div class="flex-col space-y-25 group_13">
              <span class="font_7 text_6">高能动力锂电池BMS系统</span>
              <div class="group_11">
                <span class="font_8">
                  搭载光阳独家开发的高能动力锂电池BMS系统，
                  <br />
                </span>
                <span class="font_8">使其独具高能量密度、高充放电倍率的双高性能。</span>
              </div>
            </div>
          </div> 
              <div class="image_6 image_7 hovescale" >
              <img class="image_6 "
                src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/01.gif" /></div>
            </div>
            <div class="flex-col items-center space-y-48 section_5">
              <div style="width: 100%;">
              <div class="flex-col space-y-25 group_13">
                <span class="font_7 text_7">智能换电系统</span>
                <div class="group_12">
                  <span class="font_8">
                    全方位布局Ionex 3.0 以提供多元的能源补充方式、
                    <br />
                  </span>
                  <span class="font_8">建设电池交换网路、让消费者安心拥有电动机车。</span>
                </div>
              </div>
            </div>
              <div class="image_6 hovescale">
              <img class="image_6"
                src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/02.gif" /></div>
            </div>
          </div>
          <div class="flex-row space-x-58">
            <div class="flex-col items-start space-y-48 section_5">
              <div class="flex-col space-y-25 group_13">
                <span class="font_7 text_8">智能车控系统</span>
                <div class="group_12">
                  <span class="font_8">
                    集结实用工具、便捷操控与车辆讯息，
                    <br />
                  </span>
                  <span class="font_8">生活有了 KYMCO App 更加轻松便利。</span>
                </div>
              </div>
              <div class="image_6 hovescale">
              <img class="image_6"
                src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/03.gif" /></div>
            </div>
            <div class="flex-col items-start space-y-48 section_5">
              <div class="flex-col space-y-26 group_14">
                <span class="font_7 text_9">云服务平台</span>
                <div class="group_12">
                  <span class="font_8">
                    为车主提供稳定可靠、安全可信、
                    <br />
                  </span>
                  <span class="font_8">可持续创新的云服务</span>
                </div>
              </div>
              <div class="image_6 hovescale">
                <img class="image_6"
                src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/04.gif" />
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
    <newfooter />
  </div>
</template>

<script>
export default {
  name: 'Vue3Ionx',

  data() {
    return {
      show:false
    };
  },

  mounted() {
    var that=this;
       this.$nextTick(()=>{
        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
        var box1 = document.querySelector(".space-x-11");
        var observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            if (mutation.type == "attributes") {
              setTimeout(()=>{
                that.show = true;
              },500)
            }
          });
        });
        
        observer.observe(box1, {
          attributes: true, //configure it to listen to attribute changes,
          attributeFilter: ['class']
        });
      });
  },

  methods: {

  },
};
</script>
<style>
.typed-element .typed-cursor {
   opacity: 0;
}

.typing.text:after {
    content: "";
    display: inline-block;
    width: 4px;
    height: 70px;
    margin-left: 6px;
    border-radius: 4px;
    background: rgba(0, 150, 255, 1);
    vertical-align: top;
    animation: typedjsBlink 0.7s infinite;
}
</style>
<style scoped>
.page {
  padding-bottom: 0;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.section {
  padding-bottom: 200px;
  background-image: url('https://codefun-proj-user85fe8/62fb5073689f2800114f1f25/16655368341174969567.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.image-wrapper {
  padding: 215px 0 812px;
  background-image: url(https://qiniu.kymco.life/img/tt.c94346af.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  justify-content: center;
}

.image {
  width: 673px;
  height: 53px;
}

.group {
  margin-left: 230px;
  margin-top: 116px;
  align-self: flex-start;
}

.space-y-31>*:not(:first-child) {
  margin-top: 31px;
}

.space-x-11>*:not(:first-child) {
  margin-left: 11px;
}

.font_1 {
  font-size: 72px;
  font-family: Albbr;
  line-height: 67px;
  color: #231815;
}

.text {
  line-height: 68px;
}

.section_2 {
  flex-shrink: 0;
  background-color: #0096ff;
  border-radius: 3px;
  width: 6px;
  height: 64px;
}

.image_2 {
  margin-top: 69px;
  align-self: center;
  width: 1920px;
  height: 639.99936px;
}

.group_2 {
  margin-top: 68px;
  align-self: center;
  width: 1267px;
}

.space-y-30>*:not(:first-child) {
  margin-top: 30px;
}

.font_2 {
  font-size: 28px;
  font-family: Albbr;
  line-height: 45px;
  color: #231815;
}

.font_3 {
  font-size: 27px;
  font-family: Albbr;
  line-height: 45px;
  font-weight: 700;
  color: #231815;
}

.group_3 {
  align-self: flex-start;

}

.image-wrapper_2 {
  margin-top: 96px;
  padding: 70px 0;
  background-color: #707070;
}

.image_3 {
  width: 1200px;
  height: 260px;
}

.group_4 {
  margin-top: 173px;
  align-self: center;
  width: 935px;
}

.font_4 {
  font-size: 48px;
  font-family: Albbr;
  line-height: 45px;
  color: #231815;
  text-align: center;
}

.group_5 {
  margin: 31px 14px 0 15px;
  line-height: 30px;
  text-align: center;
}

.font_5 {
  font-size: 18px;
  font-family: Albbr;
  line-height: 30px;
  color: #231815;
}

.group_6 {
  margin-top: 72px;
  align-self: center;
  width: 1063px;
}

.space-y-44>*:not(:first-child) {
  margin-top: 44px;
}

.image_4 {
  margin-left: 41px;
  margin-right: 43px;
  width: 979px;
  height: 137px;
}

.section_3 {
  align-self: flex-start;
  background-color: #000000;
  width: 390px;
  height: 1px;
}

.section_4 {
  align-self: flex-end;
  background-color: #000000;
  width: 590px;
  height: 1px;
}

.group_7 {
  padding: 30px 172px 0;
}

.font_6 {
  font-size: 24px;
  font-family: Albbr;
  line-height: 24px;
  color: #0096ff;
}

.text_2 {
  line-height: 23px;
}

.text_3 {
  margin-right: 97px;
  line-height: 23px;
}

.image-wrapper_3 {
  margin-top: 157px;
  padding: 520px 0 446px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16655368351988555010.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.image_5 {
  width: 327px;
  height: 113px;
}

.group_8 {
  margin-top: 173px;
  align-self: center;
}

.space-x-4>*:not(:first-child) {
  margin-left: 4px;
}

.text_4 {
  line-height: 44px;
}

.text_5 {
  line-height: 44px;
}

.group_9 {
  margin-top: 92px;
  align-self: center;
  width: 1018px;
}

.space-y-53>*:not(:first-child) {
  margin-top: 53px;
}

.space-x-58>*:not(:first-child) {
  margin-left: 58px;
}

.section_5 {
  flex: 1 1 500px;
  padding-top: 60px;
  background-color: #ffffff;
  border-radius: 30px;
  height: 652px;
}

.font_7 {
  font-size: 32px;
  font-family: Albbr;
  line-height: 30px;
  font-weight: 500;
  color: #231815;
}

.text_6 {
  line-height: 31px;
}

.group_11 {
  margin-top: 25px;
  line-height: 24px;
}

.font_8 {
  font-size: 16px;
  font-family: Albbr;
  line-height: 24px;
  color: #515151;
}

.image_6 {
  border-radius: 0px 0px 30px 30px;
  width: 480px;
  height: 440px;
}

.image_7 {
  margin-top: 48px;
}

.space-y-48>*:not(:first-child) {
  margin-top: 48px;
}

.group_10 {}

.text_7 {
  align-self: flex-start;
  line-height: 31px;
}

.group_12 {
  line-height: 24px;
}

.group_13 {
  margin-left: 37px;

}

.space-y-25>*:not(:first-child) {
  margin-top: 25px;
}

.text_8 {
  align-self: flex-start;
  line-height: 31px;
}

.group_14 {
  margin-left: 38px;

}

.space-y-26>*:not(:first-child) {
  margin-top: 26px;
}

.text_9 {
  align-self: flex-start;
}
</style>